@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

* {
    @apply font-body;
}

.transition{
    @apply transition-all duration-500;
}

.header-menu li a{
    @apply font-headers;
}

.header-menu li span,
.header-menu li a{
    @apply font-headers lg:text-base text-sm;
}

.dropdown-menu .nav-link a{
    @apply  whitespace-nowrap text-blackText hover:text-red transition font-headers;
}
.dropdown-menu .nav-link{
    @apply text-base font-normal md:mb-5 mb-2;
}
.dropdown-menu .nav-link:last-child{
    @apply mb-0;
}
.dropdown-menu {
    box-shadow: 0px 1px 10px 0px #00000040;
}
.dropdown-menu {
    @apply md:absolute block bg-white md:py-7 py-4 md:px-10 px-3 rounded-xl mt-2 w-auto z-10;
}

.dropdown-arrow {
    @apply transition-transform transform;
}

.active-menu{
    @apply text-red;
}

.outline-text-black {
    color: transparent; /* Text color, matching the background */
    -webkit-text-stroke-width: 2px; /* Outline thickness */
    -webkit-text-stroke-color: #3a3a3a; /* Outline color */
}

.outline-text-white {
    color: transparent; /* Text color, matching the background */
    -webkit-text-stroke-width: 2px; /* Outline thickness */
    -webkit-text-stroke-color: #ffffff; /* Outline color */
}

@media (min-width:768px) {
    .slick-track{
        display: flex;
        justify-content: center;
        align-items: stretch;
        height: 100%;
    }
}

.container{
    @apply 2xl:px-5 xl:px-4 lg:px-3 px-3;
}

header,
footer,
section{
    @apply lg:px-12 px-0;
}

#innerBanner span:last-child{
    display: none;
}


#nav-icon {
    width: 30px;
    height: 20px;
    position: relative;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .5s ease-in-out;
    -moz-transition: .5s ease-in-out;
    -o-transition: .5s ease-in-out;
    transition: .5s ease-in-out;
    cursor: pointer;
}

#nav-icon span {
    display: block;
    position: absolute;
    height: 4px;
    width: 100%;
    background: #3a3a3a;
    border-radius: 9px;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out;
}



#nav-icon span:nth-child(1) {
    top: 0px;
    -webkit-transform-origin: left center;
    -moz-transform-origin: left center;
    -o-transform-origin: left center;
    transform-origin: left center;
}

#nav-icon span:nth-child(2) {
    top: 10px;
    -webkit-transform-origin: left center;
    -moz-transform-origin: left center;
    -o-transform-origin: left center;
    transform-origin: left center;
}

#nav-icon span:nth-child(3) {
    top: 20px;
    -webkit-transform-origin: left center;
    -moz-transform-origin: left center;
    -o-transform-origin: left center;
    transform-origin: left center;
}

#nav-icon.toggle-open span:nth-child(1) {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
    top: -3px;
    left: 0px;
}

#nav-icon.toggle-open span:nth-child(2) {
    width: 0%;
    opacity: 0;
}

#nav-icon.toggle-open span:nth-child(3) {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
    top: 20px;
    left: 0px;
}


@media (max-width:767px) {
    .menu-links{
        position: fixed;
        top: 97px;
        width: 100vw;
        max-height: calc(100vh - 98px);
        overflow-y: auto;
        background: white;
        left: 0;
        z-index: -1;
        box-shadow: 0px 1px 10px 0px #00000040;
        padding: 20px 0;
    }
    .menu-links .header-menu{
        @apply container mx-auto;
    }
    .hide-menu{
        transform: translateX(-100%);
        transition: all 0.4s;
    }
    
    .show-menu{
        transform: translateX(0%);
        transition: all 0.4s;
    }
}